import React from 'react'
import { Text } from 'rebass'

import Layout from '../../components/Layout'
import { Container, BoxText } from '../../components/styled'

export const PolicyTemplate = ({ policy, title }) => (
  <Layout>
    <Container>
      <Text as="h1" fontSize={3} mb={3}>
        {title}
      </Text>
      <BoxText
        fontSize={1}
        mb={3}
        dangerouslySetInnerHTML={{
          __html: policy.childMarkdownRemark.html,
        }}
      />
    </Container>
  </Layout>
)
