import { graphql } from 'gatsby'
import React from 'react'

import SEO from '../components/SEO'
import { PolicyTemplate } from '../templates/policy'

const PolicyPage = ({ data }) => {
  const {
    title,
    policy,
    policy: {
      childMarkdownRemark: { excerpt },
    },
  } = data.contentfulPoliticas

  return (
    <React.Fragment>
      <SEO title={title} description={excerpt} />
      <PolicyTemplate title={title} policy={policy} />
    </React.Fragment>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPoliticas(slug: { eq: $slug }) {
      title
      policy {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 160)
        }
      }
    }
  }
`

export default PolicyPage
